import CanvaJs from "../components/CanvaJs";
import cards from '../const/options'
import configJson from '../const/test'
import React, {useState, useRef, forwardRef, useEffect} from "react";
import jsPDF from 'jspdf';
import printJS from 'print-js'

const Export = () => {
    const canvaRef = useRef([]);

    const [state, setState] = useState({
        busy: true,
        selectedCard: cards.cards[0],
        canvas: Array.from(Array(3).keys()),
        configJson: configJson
    });

    const printAll = () => {
        //const pngUrl = editor?.canvas.toDataURL();
    }

    const exportPdf = () => {
        const imgBase64 = []
        Object.keys(canvaRef).map((key, k)=>{
            if(typeof canvaRef[key]['current'] === 'undefined' || !canvaRef[key] && !canvaRef[key]['current'] || !canvaRef[key]['current']['canvas'] )
                return;
            const imgX = canvaRef[key]['current']['canvas'].toDataURL({format: 'png', quality: 1, multiplier: 5})
            imgBase64.push(imgX)
        })

        //console.log('imgBase64', imgBase64)
        const pdf = new jsPDF({
            orientation: 'portrait', // or 'landscape'
            unit: 'mm', // or 'in', 'pt', etc.
            format: [140, 88] // or custom dimensions
        });

        imgBase64.map((img, i)=>{
            if (i > 0) {
                pdf.addPage();
            }

            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (state.selectedCard.inch.height * imgWidth) / state.selectedCard.inch.width;

            pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);
        })

        //const dataPdf = pdf.output('datauri')

        /*var string = pdf.output('datauristring');
        var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();*/


        //var printWindow = window.open( string );
        //printWindow.print();


        //console.log('xxx000', dataPdf)
        //printJS({ showModal:true,printable: dataPdf, type: 'pdf', base64: true   })

        pdf.save('printed_images.pdf');

    }

    const getOrCreateRef = (id) => {
        if (!canvaRef.hasOwnProperty(id)) {
            canvaRef[id] = React.createRef();
        }
        return canvaRef[id];
    }

    useEffect(()=>{
        //configJson, testJson
        let finalJson = configJson
        finalJson.objects[0]['text'] = 'Ahmed Nassar'
        finalJson.objects[1]['text'] = 'جامعه طيبه'
        setState(state => ({ ...state, busy: false, configJson: finalJson }))
        //
    },['state.busy'])


    return (
        <>
          <div style={{marginBottom: 100}}>
              <button onClick={printAll} > print </button>
              <br />
              <button onClick={exportPdf} > pdf </button>
          </div>
         {!state.busy && state.canvas.map((val,k)=>(
             <div key={k}>
                 <div>{ k }</div>
                 <CanvaJs
                      key={k}
                      ref={getOrCreateRef(k)}
                      id={'canva-' + k}
                      width={state.selectedCard.inch.width}
                      height={state.selectedCard.inch.height}
                      json={state.configJson}
                 />
             </div>
         ))}
        </>
    )

}

export default Export;
