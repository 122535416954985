import {useState, useEffect, useContext} from "react";

import 'material-icons/iconfont/material-icons.css';

import {fabric} from 'fabric';
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { StoreContext } from '../App';
import options from '../const/options'
import EditorText from '../components/Editor/EditorText'
import testJson from '../const/test'
import {toast } from 'react-toastify';

fabric.IText.prototype.toObject = (function (toObject) {
    return function () {
        return fabric.util.object.extend(toObject.call(this), {
            operationKey: this.operationKey
        });
    };
})(fabric.IText.prototype.toObject);

fabric.Image.prototype.toObject = (function (toObject) {
    return function () {
        return fabric.util.object.extend(toObject.call(this), {
            operationKey: this.operationKey
        });
    };
})(fabric.Image.prototype.toObject);


const DesignScreen = () => {
    const {editor, onReady} = useFabricJSEditor({
        selection: true,
        selectionBorderColor: 'green',
        'dir': 'rtl',
    });

    const [state, setState] = useState({
        id: null,
        busy: false,
        initialize: false,
        openCardOptions: true,
        selectedCard: options.cards[0],
        selectedPattern: options.cards[0]['patterns'][0],
        templateName: null,
        userType: null,
        objectSelectedType: null,
        objectSelected: null,
    });

    const myStores = useContext(StoreContext);

    const fetchData = () => {
        myStores.apiStore.getAllLeadTypes().then(res=>{
            console.log('res', res)
        })
    }
    const setSelectCard = (row, i) => {
        setState(state => ({...state, selectedCard: row}))
    }
    const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
        const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
        const img = document.createElement('img');
        img.src = deleteIcon;
        fabric.Object.prototype.cornerColor = '#131313';
        fabric.Object.prototype.transparentCorners = false;

        const size = 20;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);
        ctx.restore();
    };
    const deleteObject = (eventData, target) => {
        const canvas = target.target.canvas;
        console.log('-->canvas', canvas);
        editor.canvas.remove(target.target);
        editor.canvas.requestRenderAll();
    };
    const setBackgroundFromDataUrl = (dataUrl, options = {}) => {

        if (!dataUrl) {
            return true;
        }
        //const canvas = editor.canvas;
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');

        fabric.Image.fromURL(dataUrl, function (img) {
            editor?.canvas?.setBackgroundImage(img);
            editor?.canvas?.requestRenderAll();
        });

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderIcon,
            cornerSize: 24
        });

        setTimeout(() => {
            editor?.canvas?.renderAll();
            addImg()
        }, 100);

    }

    const AddLogo = () => {

        const logoPath = './fullLogoTrans.png'
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');

        const cWidth = editor?.canvas.getWidth();
        const cHeight = editor?.canvas.getHeight();
        const cCenter = {x: cWidth / 2, y: cHeight / 2};

        fabric.Image.fromURL(logoPath, function (img) {

            img.scaleToWidth(200);
            img.scaleToHeight(75);
            img.operationKey = 'logo'
            editor?.canvas?.add(img);
            editor?.canvas.setActiveObject(img);

            //console.log(img.width)

            img.set({
                left: cCenter.x - (200 / 1.6),
                top: 50,
            })

            img.setCoords();

            editor.canvas.centerObject(img);
        });
    }

    const addUserAvatar = () => {
        //userImgPlzh.png
        const logoPath = './4x6frame.png'
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');

        const cWidth = editor?.canvas.getWidth();
        const cHeight = editor?.canvas.getHeight();
        const cCenter = {x: cWidth / 2, y: cHeight / 2};

        fabric.Image.fromURL(logoPath, function (img) {

            img.scaleToWidth(300);
            img.scaleToHeight(200);
            img.operationKey = 'userImg'
            editor?.canvas?.add(img);
            editor?.canvas.setActiveObject(img);

            // img.set({
            //     left : cCenter.x - (200 / 1.6),
            //     top : 50,
            // })

            //img.setCoords();

            editor?.canvas?.centerObject(img);
        });

    }

    const addFullName = () => {
        var itext = new fabric.IText('الاسم الاول واسم العائلة', {
            //left:   200,
            //top:   0,
            width: 350,
            height: 300,
            fontSize: 26,
            fill: '#000000',
            direction: 'ltr',
            selectionDirection: 'ltr',
            textAlign: 'right',
            originX: 'right',
            moveCursor: 'pointer',
            fontFamily: 'Arial',
            operationKey: 'name',
            //isEditing: true
        });

        itext.set({
            left: 260,
            top: 389,
        })
        itext.centerH()

        editor?.canvas.add(itext);
        editor?.canvas.setActiveObject(itext);
        editor.canvas.centerObject(itext);
    }

    const addJob = () => {
        var itext = new fabric.IText('الوظيفــــــــــه', {
            //left:   200,
            //top:   0,
            width: 350,
            height: 300,
            fontSize: 26,
            fill: '#000000',
            direction: 'ltr',
            selectionDirection: 'ltr',
            textAlign: 'right',
            originX: 'right',
            moveCursor: 'pointer',
            fontFamily: 'Arial',
            //isEditing: true
            operationKey: 'job',
        });

        itext.set({
            left: 211,
            top: 431,
        })
        editor?.canvas.add(itext);
        editor?.canvas.setActiveObject(itext);
        editor.canvas.centerObject(itext);
    }

    const generateJson = () => {
        let canvasJson = editor?.canvas.toJSON();
        console.log('canvasJson', canvasJson)
        console.log('canvasJson', JSON.stringify(canvasJson))
    }

    const addLabel = () => {
        var rect = new fabric.Rect({
            "left": 6.5,
            "top": 471,
            "width": 150,
            "height": 120,
            "scaleX": 2.11,
            "scaleY": 0.45,
            originX: 'left',
            originY: 'top',
            angle: 0,
            fill: 'rgba(255,0,0,1)',
            transparentCorners: false
        });

        //editor?.canvas.add(rect);


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const nameX = urlParams.get('name') ? urlParams.get('name') : 'عارض'


        var itext = new fabric.IText(nameX, {
            "left": 157,
            "top": 482,
            "width": 60.9,
            "height": 29.38,
            fontSize: 26,
            fill: 'white',
            //textBackgroundColor: 'green',
            direction: 'ltr',
            selectionDirection: 'ltr',
            textAlign: 'center',
            originX: 'center',
            moveCursor: 'pointer',
            fontFamily: 'Arial',
            operationKey: 'type',
            //isEditing: true
        });

        itext.set({
            //left : 180,
            top: 481,
        })

        //editor?.canvas.add(itext);

        var group = new fabric.Group([rect, itext], {
            //left: 70,
            //top: 94,
            //angle: -10,
        });

        editor?.canvas.add(group);
        editor?.canvas.setActiveObject(group);
        editor.canvas.centerObject(group);
    }

    const addQr = () => {
        fabric.Image.fromURL('/assets/qr.png', function(myImg) {
            var img1 = myImg.set({ left: 0, top: 350, operationKey: 'qr' });
            img1.scaleToWidth(100);
            img1.scaleToHeight(100);
            editor?.canvas.setActiveObject(img1);
            editor?.canvas.add(img1);
            editor.canvas.centerObject(img1);
        });
    }

    const addTxt = () => {
        var itext = new fabric.IText('نص فارغ', {
            //left:   200,
            //top:   0,
            width: 350,
            height: 300,
            fontSize: 26,
            fill: '#000000',
            direction: 'ltr',
            selectionDirection: 'rtl',
            textAlign: 'right',
            originX: 'right',
            moveCursor: 'pointer',
            fontFamily: 'Arial',
            operationKey: 'txt',
            //isEditing: true
        });

        itext.set({
            left: 260,
            top: 289,
        })
        itext.centerH()

        editor?.canvas.add(itext);
        editor?.canvas.setActiveObject(itext);
        editor.canvas.centerObject(itext);
    }

    const addImg = () => {
        /*let imgInput = document.getElementById('uploadedImg');
        for(var i = 0; i < 50; i++)
            imgInput.click();*/
        const canvas = editor?.canvas
        document.getElementById('uploadedImg').onchange = function handleImage(e) {
            var reader = new FileReader();
            reader.onload = function (event){
                var imgObj = new Image();
                imgObj.src = event.target.result;
                imgObj.onload = function () {
                   // console.log(event.target.result)
                    fabric.Image.fromURL(event.target.result, function (image) {
                        image.set({
                            angle: 0,
                            //padding: 10,
                            //cornersize:10,
                            height:image.height,
                            width:image.width,
                            left: 0,
                            top: 0,
                            operationKey: 'img'
                        });
                        image.scaleToWidth(200);
                        image.scaleToHeight(200);
                        image.setCoords();
                        //console.log('imgxx', image)
                        editor?.canvas.centerObject(image);
                        editor?.canvas.add(image);

                        editor?.canvas.setActiveObject(image);
                        editor?.canvas?.renderAll();
                        editor.canvas.centerObject(image);


                    });

                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onDeselect = (e) => {
        setState(state => ({...state, objectSelectedType: null, objectSelected: null}))
    }

    const editorTextChange = (changeObj) => {
        let objectSelected = editor?.canvas.getActiveObject()
        let objRefe = editor?.canvas.getActiveObject()
        let objectSelectedLabel = null

        if(objectSelected['_objects'] && objectSelected['_objects'].length > 1)
        {
            objectSelectedLabel = objectSelected['_objects'][0]
            objectSelected = objectSelected['_objects'][1]
        }

        if(changeObj.key === 'fontSize')
        {
            objectSelected.fontSize = changeObj.val
            if(objectSelectedLabel)
            {
                //objectSelectedLabel.set({fill: '#000000'})
                objectSelectedLabel.render()
            }
        }
        if(changeObj.key === 'txtColor')
            objectSelected.set({fill: changeObj.val})
        if(changeObj.key === 'fontWeight')
            objectSelected.set({fontWeight: changeObj.val})
        if(changeObj.key === 'fontStyle')
            objectSelected.set({fontStyle: changeObj.val})
        if(changeObj.key === 'textDecoration')
            objectSelected.set({underline: changeObj.val})
        if(changeObj.key === 'zIndex')
        {
            if(changeObj.val === 'SendBackward')
            {
                console.log('sendBackwards')
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
                objectSelected.sendBackwards()
            }
            else
            {
                console.log('bringToFront')
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
                objectSelected.bringToFront()
            }
        }

        if(objectSelectedLabel && changeObj.key === 'bgColor')
        {
            objectSelectedLabel.set({fill: changeObj.val})
        }


        editor?.canvas.renderAll();
    }
    const renderTextSelectionOptions = () => {
        return <EditorText type={'txt'} onChange={editorTextChange}/>
    }

    const renderImageSelectionOptions = () => {
        return <EditorText type={'img'} onChange={editorTextChange} />
    }

    const renderLabelSelectionOptions = () => {
        return <EditorText type={'label'} onChange={editorTextChange} />
    }

    const deSelectAll = () => {
        editor?.canvas.discardActiveObject().renderAll();
    }
    const onSelectObject = (obj) => {
        console.log('obj', obj)
        if(obj['selected'][0].operationKey === 'name' || obj['selected'][0].operationKey === 'job' ||  obj['selected'][0].operationKey === 'txt')
        {
            setState(state => ({...state, objectSelectedType: 'TEXT', objectSelected: obj['selected'][0]}))
        }
        if(obj['selected'][0].operationKey === 'logo' || obj['selected'][0].operationKey === 'qr' ||  obj['selected'][0].operationKey === 'userImg' ||  obj['selected'][0].operationKey === 'img')
        {
            setState(state => ({...state, objectSelectedType: 'IMAGE', objectSelected: obj['selected'][0]}))
        }

        if(obj['selected'][0]['_objects'] && obj['selected'][0]['_objects'][1].operationKey === 'type')
            setState(state => ({...state, objectSelectedType: 'LABEL', objectSelected: obj['selected'][0]['_objects'] }))

    }

    const initialize = () => {

        if(state.initialize)
            return;

        console.log('canvascanvas', editor)

        editor?.canvas.clear();

        editor?.canvas.setDimensions({width: state.selectedCard.inch.width, height: state.selectedCard.inch.height});

        setBackgroundFromDataUrl(state.selectedPattern);

        //AddLogo()
        //addUserAvatar()
        //addFullName()
        //addJob()
        //addLabel()

        /*editor?.canvas.loadFromJSON(testJson, function() {
            editor?.canvas.renderAll();
        });*/

        editor?.canvas.on({
            'selection:updated': onSelectObject,
            'selection:created': onSelectObject,
            'selection:cleared': onDeselect
        });

        setTimeout(() => {
            setState(state => ({...state, initialize: true}))

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id')
            if(id)
            {
                setState(state => ({...state, openCardOptions: false }))
                window.addEventListener('message', function(e) {
                    try {
                        const iframMsg = JSON.parse(e.data);
                        const templateJson = JSON.parse(iframMsg.json)

                        if(templateJson['widthX'] && templateJson['heightX'])
                            editor?.canvas.setDimensions({width: templateJson['widthX'], height: templateJson['heightX']});


                        editor?.canvas.loadFromJSON(templateJson, function() {
                            console.log('load all')
                            editor?.canvas.renderAll();
                        });

                    } catch (e) {

                    }
                });
            }

            setTimeout(() => {
                let canvasJson = editor?.canvas.toJSON();
                console.log('canvasJson', canvasJson)
            }, 200)

        }, 10)
    }

    const toggleOptions = () => {
        if (!state.openCardOptions)
            return setState(state => ({...state, openCardOptions: true}))

        initialize();

        return setState(state => ({...state, openCardOptions: false}))
    }
    const renderTemplateSettings = () => {

        if (!state.openCardOptions)
            return <></>

        return (
            <div
                className="fixed inset-0 z-40 flex justify-center items-center w-screen h-screen bg-blue-600/20 backdrop-blur-md cursor-not-allowed">

                <div
                    className="relative z-50 cursor-auto block max-w-[60%] bg-white/80 backdrop-blur-md rounded-lg shadow overflow-auto">
                    <div className="mb-6 p-10">
                        <div className="flex flex-row gap-2 items-center mb-5">
                            <span className="material-icons text-gray-500 !text-md">crop_free</span>
                            <h3 className="text-md font-bold text-gray-500">Choose a card size</h3>
                        </div>
                        <ul className="grid w-full gap-6 grid-cols-4">
                            {options['cards'].map((row, i) => (
                                <li key={i}>
                                    <input onChange={() => setSelectCard(row)} type="radio" id={'card-' + i}
                                           name="card-size" value={'card-' + i} className="hidden peer" required
                                           defaultChecked={i === 0 ? 'defaultChecked' : ''}/>
                                    <label htmlFor={'card-' + i}
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-200/10">
                                        <div className="h-[240px] rounded-lg p-10 flex flex-col justify-center">
                                            <span
                                                className="material-icons-outlined text-black/50 !text-4xl">assignment_ind</span>
                                            <span
                                                className='text-black/50 text-4xl font-light block py-1'>{row.name}</span>
                                            <p className="flex flex-row items-center gap-1 text-black/50">
                                                <span className="text-lg">{row.mm.width}</span>
                                                <span className="material-icons text-black/20 !text-sm">close</span>
                                                <span className="text-lg">{row.mm.height}</span>
                                            </p>
                                            <strong className="text-black/50">mm</strong>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="divider"></div>
                    <div className="p-10">
                        <div className="flex flex-row gap-2 items-center mb-5">
                            <span className="material-icons text-gray-500 !text-md">texture</span>
                            <h3 className="text-md font-bold text-gray-500">Choose a background pattern</h3>
                        </div>
                        <ul className="w-full flex flex-row items-center gap-1 ">
                            {state.selectedCard.patterns.map((row, i) => (
                                <li key={i}>
                                    <input onChange={() => setState(state => ({...state, selectedPattern: row}))}
                                           type="radio" id={'pattern-' + i} name="card-bg-pattern" value="pattern-one"
                                           className="hidden peer" required
                                           defaultChecked={i === 0 ? 'defaultChecked' : ''}/>
                                    <label htmlFor={'pattern-' + i}
                                           className="inline-flex items-center justify-between w-full min-w-[30px] text-gray-500 bg-white border-2 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-200/10">
                                        <div className="min-w-[30px] min-h-[40px] rounded-lg w-full h-full"
                                             style={{
                                                 backgroundImage: `url(` + row + `)`,
                                                 backgroundSize: 'contain',
                                                 backgroundPosition: 'center',
                                                 backgroundRepeat: 'no-repeat',
                                             }}
                                        >
                                        </div>
                                    </label>
                                </li>
                            ))}

                        </ul>
                    </div>
                    <div
                        className="p-10 bg-blue-600/10 backdrop-blur-md rounded-b-lg flex flex-row justify-between items-center min-h-12 ">
                        <div className="flex flex-col">

                        </div>
                        <button onClick={toggleOptions}
                                className="font-bold uppercase tracking-widest rounded-lg bg-xprimary hover:bg-blue-600 text-white px-10 h-[50px] transition ease-in-out duration-300">Start
                            Design
                        </button>
                    </div>
                </div>

            </div>
        )
    }

    const renderCanva = () => (
        <section className="w-full h-full overflow-y-scroll relative flex justify-center mt-6">
            <FabricJSCanvas id="canvas" className="sample-canvas" onReady={onReady} dir="rtl"/>
        </section>
    )

    const saveTemplate = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const typeId = urlParams.get('typeId')
        setState(state => ({...state, busy: true}))

        let canvasJson = editor?.canvas.toJSON();
        let apiPromise;

        canvasJson['widthX'] = state.selectedCard.inch.width;
        canvasJson['heightX'] = state.selectedCard.inch.height

        let params = {
            lead_type_id: typeId,
            info: JSON.stringify(canvasJson),
        }

        if(state.id)
        {
            params['id'] = state.id
            apiPromise = myStores.apiStore.saveNewTemplate(params)
        }else {
            apiPromise = myStores.apiStore.saveNewTemplate(params)
        }

        apiPromise.then((res)=>{
            const notify = () => toast("تم الحفظ بنجاح");
            notify();
            setState(state => ({...state, busy: false, id: res.data.id }))
        })
    }

    useEffect(() => {

        setTimeout(()=>{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id')
            if(id) {
                initialize()
            }

        }, 100);
        //fetchData();

    }, [fabric, editor, 'state.busy']);

    return (
        <div className="relative flex flex-row w-screen h-screen overflow-hidden">
            {!state.busy &&
                <>
                    { renderTemplateSettings() }
                    <div className="w-[300px] bg-xmenuone overflow-y-auto">
                        <div className="p-[30px] pb-4">
                            <div className="mb-[20px]">
                                <span className="capitalize text-white/50 block mb-3">Card size</span>
                                <div className="bg-[#363636] h-[240px] rounded-lg p-10 flex flex-col justify-center">
                                    <span
                                        className="material-icons-outlined text-white/50 !text-4xl">assignment_ind</span>
                                    <span className='text-white/50 text-4xl font-light block py-1'>{state.selectedCard.name}</span>
                                    <p className="flex flex-row items-center gap-1 text-white/50">
                                        <span className="text-lg">{state.selectedCard.mm.width}</span>
                                        <span className="material-icons text-white/20 !text-sm">close</span>
                                        <span className="text-lg">{state.selectedCard.mm.height}</span>
                                    </p>
                                    <strong className="text-white/50">mm</strong>
                                </div>

                            </div>

                        </div>
                        <div className="p-[30px] bg-xmenutwo h-full">
                            <div className="mb-[20px]">
                                <span className="capitalize text-white/50 block mb-3">Card Elements</span>
                                <div className="w-full h-[40px]">
                                    <button
                                        onClick={AddLogo}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span
                                                className="material-icons text-xprimary !text-sm">add_photo_alternate</span>
                                            <span className="text-xprimary">Add Logo</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>
                                    <button
                                        onClick={addUserAvatar}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span
                                                className="material-icons text-xprimary !text-sm">account_circle</span>
                                            <span className="text-xprimary">Add Photo</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>
                                    <button
                                        onClick={addFullName}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">title</span>
                                            <span className="text-xprimary">Add Full Name</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>
                                    <button
                                        onClick={addJob}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">title</span>
                                            <span className="text-xprimary">Add Job Title</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>
                                    <button
                                        onClick={addQr}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">qr_code_2</span>
                                            <span className="text-xprimary">Add QR</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>
                                    <button
                                        onClick={addLabel}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">label</span>
                                            <span className="text-xprimary">Add Badge</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>


                                    <button
                                        onClick={addTxt}
                                        className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">title</span>
                                            <span className="text-xprimary">Add Text</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>

                                    <div>
                                        <label htmlFor="uploadedImg" className="btn">
                                            <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">add</span>
                                                Add Image
                                            </div>
                                        </label>
                                        <input style={{visibility: 'hidden'}} id="uploadedImg" type="file" />
                                    </div>


                                    <button onClick={generateJson}
                                            className="w-full h-full px-3 rounded-lg mb-2 flex justify-between items-center bg-xbtntwo hover:bg-xbtntwoh transition ease-in-out duration-300">
                                        <div className="flex items-center gap-2">
                                            <span className="material-icons text-xprimary !text-sm">label</span>
                                            <span className="text-xprimary">Genrate Json</span>
                                        </div>
                                        <span className="material-icons text-xprimary !text-sm">add</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={` ${1 ? 'w-[calc(100%-300px)]' : 'w-full'} `}>

                        <nav className="flex flex-wrap items-center mx-auto p-4 bg-xmenuone h-[80px] text-white">
                            <div className="flex relative">
                                <button
                                    onClick={saveTemplate}
                                    className="fixed top-0 right-0 bg-xprimary hover:bg-blue-600 flex justify-center items-center gap-2 w-32 h-[80px] transition ease-in-out duration-300">
                                    <span className="material-icons text-white !text-md">save</span>
                                    <strong className="uppercase">Save</strong>
                                </button>
                                <button className='button'>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.7" y="0.7" width="26.6" height="26.6" rx="6.3" fill="#3A3A3A"
                                              stroke="white" strokeWidth="1.4"/>
                                        <line x1="10.7" y1="1" x2="10.7" y2="27" stroke="white" strokeWidth="1.4"/>
                                        <path d="M4 6.5H7" stroke="white" strokeLinecap="round"/>
                                        <path d="M4 9.5H7" stroke="white" strokeLinecap="round"/>
                                        <path d="M4 12.5H7" stroke="white" strokeLinecap="round"/>
                                    </svg>
                                </button>
                                <div className="divider divider-horizontal"></div>
                                {state.objectSelected &&
                                    <button
                                        className={`min-w-[44px] min-h-[40px] bg-xbtnone hover:bg-white/20 transition ease-in-out duration-300 rounded-lg px-3 flex flex-row justify-center items-center gap-2`}
                                        onClick={() => deSelectAll()} // Send Backward
                                    >
                                        <span className="material-icons text-white !text-xl">deselect</span>
                                        <span>DeSelect</span>
                                    </button>
                                }
                                <div className="divider divider-horizontal"></div>

                                {state.objectSelectedType === 'TEXT' && renderTextSelectionOptions() }
                                {state.objectSelectedType === 'IMAGE' && renderImageSelectionOptions() }
                                {state.objectSelectedType === 'LABEL' && renderLabelSelectionOptions() }

                            </div>
                        </nav>

                        {renderCanva()}
                    </div>
                </>
            }
            {state.busy &&
                <div className={"loader-wrapper"}>
                    <ScaleLoader color={'red'} loading={true} size={150}/>
                </div>
            }


        </div>
    )
}

export default DesignScreen;
