import React, { useState, useRef, useEffect } from 'react';

const { fabric } = window;

// this component takes one prop, data, which is an object with data.sizePixels, an array that represents the width,height of the "backgroundObject"

const CanvasComponent = React.forwardRef((props, ref) => {


    const canvasContainer = useRef();
    const canvasRef = useRef();
    const fabricRef = useRef();
    const [error, setError] = useState();

    const initCanvas = ({ canvas, size }) => {
        //console.log('*** canvas init');
        //console.log(canvas);

        if(props.json)
        {
            canvas.loadFromJSON(props.json, function() {
                canvas.renderAll();
            });
        }
    };

    useEffect(() => {
        if (!canvasRef.current) return;
        if (fabricRef.current) return;
        const canvas = new fabric.Canvas(props.id, {
            width: props.width,
            height: props.height,
            backgroundColor: 'lightGrey',
        });
        fabricRef.current = canvas;
        initCanvas({ canvas });

        ref.current = {
            canvas: canvas
        };
    });

    if (error) {
        return (
            <p>{error}</p>
        );
    }
    return (
        <>
            <div ref={canvasContainer} style={{ flex: 7, backgroundColor: 'white' }}>
                <canvas id={props.id} ref={canvasRef} style={{ border: '1px solid red' }} />
            </div>
        </>
    );
});

export default CanvasComponent;
