import { createContext } from "react";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";


import HomeScreen from '../screens/HomeScreen'
import DesignScreen from '../screens/DesignScreen'
import ExportScreen from '../screens/ExportScreen'

const RootNavigator = () => {
  return (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/design" element={<DesignScreen />} />
            <Route path="/export" element={<ExportScreen />} />

          </Routes>
        </BrowserRouter>
  )
};


export default RootNavigator;
