import { Link } from "react-router-dom";

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';


const HomeScreen = (props) => {
  const navigate = useNavigate();
  const myStores = useContext(StoreContext);

  return (
      <>
        Welcome :(;
      </>
  )
};


export default HomeScreen;
