class ApiStore {

    constructor() {
        this.endpoint = 'https://maxcards.max-event.com/Api/en'
    }

    get = async (uri) => {
        try {
            const url = this.endpoint + uri;
            const request = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const response = await fetch(url, request);
            const json = await response.json();

            console.log('-->fetch post', url, request, json);
            if (json.status !== 'ok') {
                console.log('-->notok1', json);
                return Promise.reject(json);
            }
            return Promise.resolve(json);
        } catch (error) {
            console.log('catchError', error);
            return Promise.reject(error)
        }
    }

    post = async (uri, params) => {
        try {
            const url = this.endpoint + uri;
            const request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( params )
            };

            const response = await fetch(url, request);
            const json = await response.json();

            console.log('-->fetch post', url, request, json);
            if (json.status !== 'ok') {
                console.log('-->notok1', json);
                return Promise.reject(json);
            }
            return Promise.resolve(json);
        } catch (error) {
            console.log('catchError', error);
            return Promise.reject(error)
        }
    }
    getAllLeadTypes = async () => {
        return this.get('/lead-type')
    }

    saveNewTemplate = async (params) => {
        return this.post('/template/store', params)
    }


}


export default ApiStore;
