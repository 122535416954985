module.exports = {
    'cards': [
        {
            name: 'Large Format',
            desc: '3.46 X 5.51',
            mm: {
                width: 88,
                height: 140,
            },
            inch: {
                width: 332,
                height: 529,
            },
            patterns: [
                './assets/cards/140X88/0.png',
                './assets/cards/140X88/1.png',
                './assets/cards/140X88/3.jpeg',
            ]
        },
        {
            name: 'CR100',
            desc: '67 x 99',
            mm: {
                width: 67,
                height: 99,
            },
            inch: {
                width: 253.22834646,
                height: 374.17322835,
            },
            patterns: [
                './assets/cards/140X88/1.png'
            ]
        },
        {
            name: 'CR80',
            desc: '54x86',
            mm: {
                width: 54,
                height: 86,
            },
            inch: {
                width: 204.09448819,
                height: 325.03937008,
            },
            patterns: [
                './assets/cards/140X88/1.png'
            ]
        },
        {
            name: 'CR79',
            desc: '85.6x54',
            mm: {
                width: 85.6,
                height: 54,
            },
            inch: {
                width: 323.52755906,
                height: 204.09448819,
            },
            patterns: [
                './assets/cards/140X88/1.png'
            ]
        }
    ],
}
