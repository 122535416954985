import {useEffect, useState} from "react";


const EditorText = (props) => {
    const [state, setState] = useState({
        obj: props.obj,
        objType: props.type,
        fontSize: 26,
        txtColor: '#000000',
        bgColor: '#FF0000',
        fontWeight: 'normal',
        fontStyle: 'normal',
    });

    const [inlineStyles, setInlineStyles] = useState({
        position: 'relative',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'left',
        lineHeight: 'inherit',
    });


    const changeFontSize = (nVal) => {
        setState(state => ({...state, fontSize: nVal}))
        props.onChange({
            key: 'fontSize',
            val: nVal
        })
    }
    const changeTxtColor = (nVal) => {
        setState(state => ({...state, txtColor: nVal}))
        props.onChange({
            key: 'txtColor',
            val: nVal
        })
    }
    const changeBgColor = (nVal) => {
        setState(state => ({...state, bgColor: nVal}))
        props.onChange({
            from: 'LABEL',
            key: 'bgColor',
            val: nVal
        })
    }
    const toggleBold = () => {
        let fontWeight = inlineStyles.fontWeight === 'bold' ? 'normal' : 'bold';

        let nStyles = inlineStyles
        nStyles.fontWeight = nStyles.fontWeight === 'bold' ? 'normal' : 'bold';

        setInlineStyles((prevStyles) => ({
            ...prevStyles,
            fontWeight: fontWeight
        }));
        props.onChange({
            key: 'fontWeight',
            val: nStyles.fontWeight
        })

    };
    const toggleItalic = () => {
        let fontStyle = inlineStyles.fontStyle === 'italic' ? 'normal' : 'italic';

        setInlineStyles((prevStyles) => ({
            ...prevStyles,
            fontStyle: fontStyle
        }));
        props.onChange({
            key: 'fontStyle',
            val: fontStyle
        })

    };
    const toggleUnderline = () => {
        let textDecoration = inlineStyles.textDecoration === 'underline' ? 'none' : 'underline';

        setInlineStyles((prevStyles) => ({
            ...prevStyles,
            textDecoration: textDecoration
        }));
        props.onChange({
            key: 'textDecoration',
            val: inlineStyles.textDecoration === 'underline'
        })

    };

    const handleZIndexChange = (val) => {
        if(val < 0)
        {
            props.onChange({
                key: 'zIndex',
                val: 'SendBackward'
            })
            return
        }

        props.onChange({
            key: 'zIndex',
            val: 'BringForward'
        })
    }

    useEffect(() => {
        //logo userImg name job type qr
        /*if ((state.obj && state.obj.operationKey ) && (state.obj.operationKey === 'name' || state.obj.operationKey === 'job'))
            setState(state => ({...state, objType: 'txt'}))
        else
            setState(state => ({...state, objType: 'img'}))*/
    },['state.objType'])

    return (
        <div className="flex flex-row items-center gap-5">
            <div className="group flex flex-row gap-2">
                <button
                    className={`min-w-[44px] min-h-[40px] bg-xbtnone hover:bg-white/20 transition ease-in-out duration-300 rounded-lg px-3 flex flex-row justify-center items-center gap-2`}
                    onClick={() => handleZIndexChange(-1)} // Send Backward
                >
                    <span className="material-icons text-white !text-xl">south_west</span>
                    <span>Send Backward</span>
                </button>
                <button
                    className={`min-w-[44px] min-h-[40px] bg-xbtnone hover:bg-white/20 transition ease-in-out duration-300 rounded-lg px-3 flex flex-row justify-center items-center gap-2`}
                    onClick={() => handleZIndexChange(1)} // Bring Forward
                >
                    <span className="material-icons text-white !text-xl">north_east</span>
                    <span>Bring Forward</span>
                </button>
            </div>
            <div className="group flex flex-row gap-2">
                {state.objType !== 'img' &&
                <div
                    className="flex flex-row justify-between items-center bg-xbtnone rounded-lg">
                    <button
                        onClick={() => changeFontSize(state.fontSize - 1)}
                        className="p-2 bg-white/10 hover:bg-white/20 transition ease-in-out duration-300 rounded-lg min-w-[40px]">
                        <span className="material-icons text-white !text-sm">remove</span>
                    </button>
                    <span className="px-3">{state.fontSize}</span>
                    <button
                        onClick={() => changeFontSize(state.fontSize + 1)}
                        className="p-2 bg-white/10 hover:bg-white/20 transition ease-in-out duration-300 rounded-lg min-w-[40px]">
                        <span className="material-icons text-white !text-sm">add</span></button>
                </div>
                }

                {state.objType !== 'img' &&
                <div
                    className="bg-xbtnone hover:bg-white/20 transition ease-in-out duration-300 rounded-lg px-3 flex flex-row gap-3 justify-evenly items-center">
                    <span className="material-icons text-white !text-xl">format_color_text</span>
                    <input
                        type="color"
                        id="colorPicker"
                        value={state.txtColor}
                        onChange={(e) => changeTxtColor(e.target.value)}
                        className="cursor-pointer"
                        style={{
                            backgroundColor: state.txtColor,
                            appearance: 'none',
                            border: 'none',
                            outline: 'none',
                            borderRadius: '100%',
                            width: '16px',
                            height: '16px',
                            padding: 0,
                            margin: 0
                        }}
                    />
                </div>
                }
                {state.objType !== 'img' &&
                    <>
                <button onClick={toggleBold}
                        className={` min-w-[44px] min-h-[40px] ${inlineStyles.fontWeight === 'bold' ? 'bg-white/30 hover:bg-white/40' : 'bg-xbtnone hover:bg-white/20'} transition ease-in-out duration-300 rounded-lg px-4 flex flex-row justify-center items-center `}>
                    <strong className="text-lg">B</strong>
                </button>
                <button onClick={toggleItalic}
                        className={` min-w-[44px] min-h-[40px] ${inlineStyles.fontStyle === 'italic' ? 'bg-white/30 hover:bg-white/40' : 'bg-xbtnone hover:bg-white/20'} transition ease-in-out duration-300 rounded-lg px-4 flex flex-row justify-center items-center `}>
                    <span className="text-lg italic">I</span>
                </button>
                <button onClick={toggleUnderline}
                        className={` min-w-[44px] min-h-[40px] ${inlineStyles.textDecoration === 'underline' ? 'bg-white/30 hover:bg-white/40' : 'bg-xbtnone hover:bg-white/20'} transition ease-in-out duration-300 rounded-lg px-4 flex flex-row justify-center items-center `}>
                    <span className="text-lg underline">U</span>
                </button>
                    </>
            }


                {state.objType === 'label' &&
                    <div
                        className="bg-xbtnone hover:bg-white/20 transition ease-in-out duration-300 rounded-lg px-3 flex flex-row gap-3 justify-evenly items-center">
                        <span className="material-icons text-white !text-xl">format_color_fill</span>
                        <input
                            type="color"
                            id="colorPicker"
                            value={state.bgColor}
                            onChange={(e) => changeBgColor(e.target.value)}
                            className="cursor-pointer"
                            style={{
                                backgroundColor: state.bgColor,
                                appearance: 'none',
                                border: 'none',
                                outline: 'none',
                                borderRadius: '100%',
                                width: '16px',
                                height: '16px',
                                padding: 0,
                                margin: 0
                            }}
                        />
                    </div>
                }

            </div>
        </div>
    )
}

export default EditorText;
